import { Link } from "react-router-dom"
import Footer from "../../components/footer"
import Header from "../../components/header"
import SideBar from "../../components/sidebar"

const TaxiDetail = () => {
    return (<>
        <Header />
        <SideBar />
        <main class="main">

            <div class="site-breadcrumb" style={{ background: 'url(/assets/img/breadcrumb/01.jpg)' }}>
                <div class="container">
                    <h2 class="breadcrumb-title">Taxi Single</h2>
                    <ul class="breadcrumb-menu">
                        <li><a href="index.html">Home</a></li>
                        <li class="active">Taxi Single</li>
                    </ul>
                </div>
            </div>


            <div class="taxi-single pt-120 pb-100">
                <div class="container">
                    <div class="taxi-single-wrapper">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="taxi-single-img">
                                    <img src="/assets/img/taxi/01.png" alt />
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="taxi-single-details">
                                    <div class="taxi-single-top">
                                        <span class="taxi-single-tagline">Best Taxi Service</span>
                                        <h3 class="taxi-single-title">Mercedes Benz Taxi</h3>
                                        <ul class="taxi-single-meta">
                                            <li><i class="far fa-circle-dollar"></i><span>Base Rate:</span> $2.30/km</li>
                                            <li><i class="far fa-circle-check"></i><span>Booking Fee:</span> $0.99</li>
                                        </ul>
                                        <div class="taxi-single-btn">
                                            <Link to="/booknow" class="theme-btn">Book Taxi Now<i
                                                class="fas fa-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                    <div class="taxi-key-info">
                                        <h4 class="taxi-key-title">Key Information</h4>
                                        <div class="row">
                                            <div class="col-xl-4 col-6">
                                                <div class="taxi-key-item">
                                                    <div class="taxi-key-icon">
                                                        <i class="far fa-car-side-bolt"></i>
                                                    </div>
                                                    <div class="taxi-key-content">
                                                        <p>Taxi Doors</p>
                                                        <span>4</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-6">
                                                <div class="taxi-key-item">
                                                    <div class="taxi-key-icon">
                                                        <i class="far fa-person-seat"></i>
                                                    </div>
                                                    <div class="taxi-key-content">
                                                        <p>Passengers</p>
                                                        <span>4</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-6">
                                                <div class="taxi-key-item">
                                                    <div class="taxi-key-icon">
                                                        <i class="far fa-suitcase-rolling"></i>
                                                    </div>
                                                    <div class="taxi-key-content">
                                                        <p>Luggage Carry</p>
                                                        <span>3</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-6">
                                                <div class="taxi-key-item">
                                                    <div class="taxi-key-icon">
                                                        <i class="far fa-steering-wheel"></i>
                                                    </div>
                                                    <div class="taxi-key-content">
                                                        <p>Transmission</p>
                                                        <span>Autometic</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-6">
                                                <div class="taxi-key-item">
                                                    <div class="taxi-key-icon">
                                                        <i class="far fa-calendar-days"></i>
                                                    </div>
                                                    <div class="taxi-key-content">
                                                        <p>Build Year</p>
                                                        <span>2023</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-6">
                                                <div class="taxi-key-item">
                                                    <div class="taxi-key-icon">
                                                        <i class="far fa-gas-pump"></i>
                                                    </div>
                                                    <div class="taxi-key-content">
                                                        <p>Fuel Type</p>
                                                        <span>Diesel</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-6">
                                                <div class="taxi-key-item">
                                                    <div class="taxi-key-icon">
                                                        <i class="far fa-heat"></i>
                                                    </div>
                                                    <div class="taxi-key-content">
                                                        <p>Air Condition</p>
                                                        <span>Yes</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-6">
                                                <div class="taxi-key-item">
                                                    <div class="taxi-key-icon">
                                                        <i class="far fa-map-location-dot"></i>
                                                    </div>
                                                    <div class="taxi-key-content">
                                                        <p>GPS Navigatio</p>
                                                        <span>Yes</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-6">
                                                <div class="taxi-key-item">
                                                    <div class="taxi-key-icon">
                                                        <i class="far fa-user-pilot"></i>
                                                    </div>
                                                    <div class="taxi-key-content">
                                                        <p>Driver Choose</p>
                                                        <span>Yes</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-6">
                                                <div class="taxi-key-item">
                                                    <div class="taxi-key-icon">
                                                        <i class="far fa-engine"></i>
                                                    </div>
                                                    <div class="taxi-key-content">
                                                        <p>Engine Size</p>
                                                        <span>2,000 (cc)</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-6">
                                                <div class="taxi-key-item">
                                                    <div class="taxi-key-icon">
                                                        <i class="far fa-registered"></i>
                                                    </div>
                                                    <div class="taxi-key-content">
                                                        <p>VIN</p>
                                                        <span>682GAFDRTD</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="taxi-single-overview">
                                    <h4 class="mb-3">Description</h4>
                                    <div class="mb-4">
                                        <p>There are many variations of passages orem psum available but the majority have
                                            suffered alteration in some form by injected humour or randomised words which
                                            don't look even slightly believable. If you are going to use a passage of Lorem
                                            Ipsum, you need to be sure there isn't anything embarrassing hidden in the
                                            middle of text. All the Lorem Ipsum generators on the Internet tend to repeat
                                            predefined chunks as necessary making this the first true generator on the
                                            internet.</p>
                                        <p class="mt-2">It is a long established fact that a reader will be distracted by
                                            the readable content of a page when looking at its layout. The point of using
                                            Lorem Ipsum is that it has a more-or-less normal distribution of letters as
                                            opposed to using Content here content here making it look like readable English.
                                        </p>
                                    </div>
                                    <h4 class="mb-3">Taxi Features</h4>
                                    <div class="row mb-3">
                                        <div class="col-lg-4">
                                            <ul class="taxi-single-list">
                                                <li><i class="far fa-check-circle"></i> Multi-zone A/C</li>
                                                <li><i class="far fa-check-circle"></i> Heated front seats</li>
                                                <li><i class="far fa-check-circle"></i> Navigation system</li>
                                                <li><i class="far fa-check-circle"></i> Leather seats</li>
                                            </ul>
                                        </div>
                                        <div class="col-lg-4">
                                            <ul class="taxi-single-list">
                                                <li><i class="far fa-check-circle"></i> Premium sound system</li>
                                                <li><i class="far fa-check-circle"></i> Bluetooth</li>
                                                <li><i class="far fa-check-circle"></i> Andriod Auto</li>
                                                <li><i class="far fa-check-circle"></i> Intermittent wipers</li>
                                            </ul>
                                        </div>
                                        <div class="col-lg-4">
                                            <ul class="taxi-single-list">
                                                <li><i class="far fa-check-circle"></i> Memory seat</li>
                                                <li><i class="far fa-check-circle"></i> Adaptive Cruise Control</li>
                                                <li><i class="far fa-check-circle"></i> Cooled Seats</li>
                                                <li><i class="far fa-check-circle"></i> Keyles Start</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="mt-2">
                                        <p>Many variations of passages orem psum available but the majority have suffered
                                            alteration in some form by injected humour or randomised words which don't look
                                            even slightly believable. If you are going to use a passage of Lorem Ipsum, you
                                            need to be sure there isn't anything embarrassing hidden in the middle of text.
                                            All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks
                                            as necessary making this the first true generator on the internet.</p>
                                        <p class="mt-2">Long established fact that a reader will be distracted by the
                                            readable content of a page when looking at its layout. The point of using Lorem
                                            Ipsum is that it has a more-or-less normal distribution of letters as opposed to
                                            using Content here content here making it look like readable English.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="related-taxi pb-120">
                <div class="container">
                    <div class="taxi-single-related">
                        <div class="row">
                            <div class="col-lg-6 mx-auto">
                                <div class="site-heading text-center">
                                    <span class="site-title-tagline">Related Taxi</span>
                                    <h2 class="site-title">Best Similar Taxi For You</h2>
                                    <div class="heading-divider"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-lg-4">
                                <div class="taxi-item">
                                    <div class="taxi-img">
                                        <img src="/assets/img/taxi/01.png" alt />
                                    </div>
                                    <div class="taxi-content">
                                        <div class="taxi-head">
                                            <h4>BMW M5 2019 Model</h4>
                                            <span>$1.25/km</span>
                                        </div>
                                        <div class="taxi-feature">
                                            <ul>
                                                <li><i class="far fa-car-side-bolt"></i> Taxi Doors: <span>4</span></li>
                                                <li><i class="far fa-person-seat"></i> Passengers: <span>4</span></li>
                                                <li><i class="far fa-suitcase-rolling"></i> Luggage Carry: <span>2</span>
                                                </li>
                                                <li><i class="far fa-heat"></i> Air Condition: <span>Yes</span></li>
                                                <li><i class="far fa-map-location-dot"></i> GPS Navigation: <span>Yes</span>
                                                </li>
                                                <li><i class="far fa-user-pilot"></i> Driver Choosing: <span>Yes</span></li>
                                            </ul>
                                        </div>
                                        <Link to="/booknow" class="theme-btn">Book Taxi Now<i
                                                class="fas fa-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4">
                                <div class="taxi-item">
                                    <div class="taxi-img">
                                        <img src="/assets/img/taxi/01.png" alt />
                                    </div>
                                    <div class="taxi-content">
                                        <div class="taxi-head">
                                            <h4>BMW M5 2019 Model</h4>
                                            <span>$1.25/km</span>
                                        </div>
                                        <div class="taxi-feature">
                                            <ul>
                                                <li><i class="far fa-car-side-bolt"></i> Taxi Doors: <span>4</span></li>
                                                <li><i class="far fa-person-seat"></i> Passengers: <span>4</span></li>
                                                <li><i class="far fa-suitcase-rolling"></i> Luggage Carry: <span>2</span>
                                                </li>
                                                <li><i class="far fa-heat"></i> Air Condition: <span>Yes</span></li>
                                                <li><i class="far fa-map-location-dot"></i> GPS Navigation: <span>Yes</span>
                                                </li>
                                                <li><i class="far fa-user-pilot"></i> Driver Choosing: <span>Yes</span></li>
                                            </ul>
                                        </div>
                                        <Link to="/booknow" class="theme-btn">Book Taxi Now<i
                                                class="fas fa-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4">
                                <div class="taxi-item">
                                    <div class="taxi-img">
                                        <img src="/assets/img/taxi/01.png" alt />
                                    </div>
                                    <div class="taxi-content">
                                        <div class="taxi-head">
                                            <h4>BMW M5 2019 Model</h4>
                                            <span>$1.25/km</span>
                                        </div>
                                        <div class="taxi-feature">
                                            <ul>
                                                <li><i class="far fa-car-side-bolt"></i> Taxi Doors: <span>4</span></li>
                                                <li><i class="far fa-person-seat"></i> Passengers: <span>4</span></li>
                                                <li><i class="far fa-suitcase-rolling"></i> Luggage Carry: <span>2</span>
                                                </li>
                                                <li><i class="far fa-heat"></i> Air Condition: <span>Yes</span></li>
                                                <li><i class="far fa-map-location-dot"></i> GPS Navigation: <span>Yes</span>
                                                </li>
                                                <li><i class="far fa-user-pilot"></i> Driver Choosing: <span>Yes</span></li>
                                            </ul>
                                        </div>
                                        <Link to="/booknow" class="theme-btn">Book Taxi Now<i
                                                class="fas fa-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
        <Footer/>
    </>
    )
}
export default TaxiDetail