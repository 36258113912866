import { Link, useParams } from "react-router-dom"
import DashboardSideBar from "../../components/dashboardSidebar"
import Footer from "../../components/footer"
import Header from "../../components/header"
import SideBar from "../../components/sidebar"
import services from "../../services"
import { useEffect, useState } from "react"
import moment from "moment"
import constant from "../../constant"

import { Rating } from 'react-simple-star-rating'
const OrderDetails = () => {

    const params = useParams()
    const [bookingDetail, setBookingDetail] = useState()
    const [isPaymentDone, setIsPaymentDone] = useState()
    const [isPayment, setIsPayment] = useState()
    const [mealCost, setMealCost] = useState()
    const [tollTax, setTollTax] = useState()
    const [otherCost, setOtherCost] = useState()
    const [paymentMode, setPaymentMode] = useState()
    const [paymentRef, setPaymentRef] = useState()
    const [image, setImage] = useState()
    useEffect(() => {
        getBookingsHandler();
    }, [])
    const getBookingsHandler = async () => {
        try {
            const response = await services.order.BOOKING_DETAIL(params.id);
            let data = response.data;
            
            data.BookingDetails.sort(function(a, b) {
                    return parseFloat(a.id) - parseFloat(b.id);
                });
           
            setBookingDetail(data)

            if (data?.BookingDetails[0]?.BookingPayment?.BookingPaymentDetails[0]?.paymentStatus =='success') {
                setIsPayment(data?.BookingDetails[0]?.BookingPayment?.BookingPaymentDetails[0]?.amount);
                setIsPaymentDone(data?.BookingDetails[0]?.BookingPayment?.BookingPaymentDetails[0]?.paymentStatus)
            }
            if (data?.BookingDetails[0]?.bookingStatus == 'ended') {
                let index = data?.BookingDetails[0]?.BookingPayment.BookingPaymentDetails.length;
                let obj = data?.BookingDetails[0]?.BookingPayment.BookingPaymentDetails[index - 1].transactionData;

                setMealCost(obj.mealCost)
                setTollTax(obj.tollTax)
                setOtherCost(obj.otherCost)
                setPaymentRef(obj.paymentRef)
                setPaymentMode(obj.paymentMode)
                if(data?.BookingDetails[0]?.amountBreakdown.remainingAmount){
                    let totalAccoupants = bookingDetail.carData.customerCapacity - bookingDetail.sharingAvailable;
                setMealCost(obj.mealCost*totalAccoupants)
                setTollTax(obj.tollTax*totalAccoupants)
                setOtherCost(obj.otherCost*totalAccoupants)
                setPaymentRef(obj.paymentRef)
                setPaymentMode(obj.paymentMode)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    const changeStatusHandler = async () => {
        try {
            let data = {
                bookingStatus: 'started',
                bookingId: bookingDetail.id,
                bookingDetailId: bookingDetail.BookingDetails[0].id,
            }
            const response = await services.order.UPDATE_STATUS(data);
            console.log(response)
            getBookingsHandler()
        } catch (error) {
            console.log(error)
        }
    }
    const calculationHandler = () => {
        // let remainingcost = (((bookingDetail.distanceBreakdown.distance / 1000) * (bookingDetail.isAcAvailable ? bookingDetail.VendorCar?.perKmCharge+1 : bookingDetail.VendorCar?.perKmCharge)) - (isPayment / 100))
        let remainingcost = ((bookingDetail.distanceBreakdown.distance / 1000) * (bookingDetail.isAcAvailable ? bookingDetail.VendorCar?.perKmCharge+1 : bookingDetail.VendorCar?.perKmCharge))
        let total = remainingcost
        if (mealCost) {
            total = total + (+mealCost);
        }
        if (tollTax) {
            total = total + (+tollTax);
        }
        if (otherCost) {
            total = total + (+otherCost);
        }
        console.log(typeof (mealCost), remainingcost + mealCost)
        return total.toFixed(0)
    }
    const calculationSharingHandler = (item) => {
        let totalAccoupants = bookingDetail.carData.customerCapacity - bookingDetail.sharingAvailable;
        let remainingcost = item.amountBreakdown.remainingAmount;
        let total = remainingcost;
        let subtotal = 0
        if (mealCost) {
            subtotal = subtotal + (+mealCost);
        }
        if (tollTax) {
            subtotal = subtotal + (+tollTax);
        }
        if (otherCost) {
            subtotal = subtotal + (+otherCost);
        }
        total = (total+((subtotal/totalAccoupants)*item.passengerCount));
        return Math.round(total);
    }
    const completeTripHandler = async() => {
        try {
            let payload = {
                bookingDetailId : bookingDetail.BookingDetails[0].id,
                amount:calculationHandler(),
                transactionData : {
                    mealCost,
                    tollTax,
                    otherCost,
                    paymentRef,
                    paymentMode
                }
            }
            const response = await services.order.CREATE_PAYMENT(payload)
            let data = {
                bookingStatus: 'ended',
                bookingId: bookingDetail.id,
                bookingDetailId: bookingDetail.BookingDetails[0].id
            }
            const status = await services.order.UPDATE_STATUS(data);
            getBookingsHandler()
        } catch (error) {
            console.log(error)
        }
    }
    const completeSharingTripHandler = async() => {
        try {
            let totalAccoupants = bookingDetail.carData.customerCapacity - bookingDetail.sharingAvailable;
            bookingDetail.BookingDetails.map(async(item,index)=>{
                let payload = {
                    bookingDetailId : item.id,
                    amount:index == 0 ? calculationSharingHandler(item) - item.amountBreakdown.commissionPaid : calculationSharingHandler(item),
                    transactionData : {
                        mealCost : (mealCost/totalAccoupants)*item.passengerCount,
                        tollTax: (tollTax/totalAccoupants)*item.passengerCount,
                        otherCost : (otherCost/totalAccoupants)*item.passengerCount,
                        paymentRef,
                        paymentMode
                    }
                }
                const response = await services.order.CREATE_PAYMENT(payload)
                let data = {
                    bookingStatus: 'ended',
                    bookingId: bookingDetail.id,
                    bookingDetailId: item.id
                }
                const status = await services.order.UPDATE_STATUS(data);
            })
            getBookingsHandler()
        } catch (error) {
            console.log(error)
        }
    }
    return (<>
        <Header />
        <SideBar />
        <main class="main">




            <div class="user-profile py-5">
                <div class="container">
                    <div class="row">
                        <DashboardSideBar />
                        <div class="col-lg-9">
                            <div class="user-profile-wrapper">
                                <div class="row">
                                    <div class="col-lg-12">
                                        {bookingDetail && <div class="user-profile-card">
                                            <div class="user-profile-card-header">
                                                <h4 class="user-profile-card-title">Booking Detail #{bookingDetail.id}</h4>

                                            </div>
                                            <div class="row">

                                                <div class="col-md-12 mx-auto">
                                                    <div class="booking-form shadow-none">

                                                        <div class="col-md-12 col-lg-12 filter-item cat1 cat2">
                                                            <div class="row align-items-center ">
                                                                <a className="col-md-6 col-12"><div class="taxi-img">
                                                                    <img src={constant.IMAGE_URL + bookingDetail?.VendorCar.photos[0]} crossorigin="anonymous" width={'350px'} />
                                                                </div></a>
                                                                <div class="taxi-content col-md-6 col-12">
                                                                    <div class="taxi-head">
                                                                        <span> <b>Selected Car</b></span><h4>{bookingDetail.Car.brand} {bookingDetail.Car.name}</h4>

                                                                    </div>
                                                                    <div class="taxi-feature">
                                                                        <ul className="">
                                                                            <li> Estimated KM: <span> <b>{(bookingDetail.distanceBreakdown.distance / 1000).toFixed(0)} KM</b></span></li>
                                                                            <li> Trip Type: <span> <b className="text-capitalize">{bookingDetail.tripType}</b></span></li>
                                                                            <li> Assigned Car Numbers: <span> <b>{bookingDetail.carData?.vendorCar?.rcNumber}</b></span></li>
                                                                            <li> Total Amount to be paid: <span> <b>Rs. {((bookingDetail.distanceBreakdown.distance / 1000) * (bookingDetail.isAcAvailable ? bookingDetail.VendorCar?.perKmCharge+1 : bookingDetail.VendorCar?.perKmCharge)).toFixed(0)}</b></span></li>
                                                                            {/* <li> Amount paid online: <span> <b className="bg-success text-white">Rs. {isPayment && isPayment / 100}</b></span></li> */}


                                                                        </ul>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        {bookingDetail?.vendorData && <><div class="user-profile-card-header mt-2">
                                                            <h4 class="user-profile-card-title">Partner Details</h4>

                                                        </div>
                                                            <div class="team-single-wrapper">
                                                                <div class="row align-items-center">
                                                                    <div class="col-lg-3">
                                                                        <div class="team-single-img">
                                                                            <img src={constant.IMAGE_URL + (bookingDetail?.vendorData?.VendorDetail?.businessLogo || bookingDetail?.Car?.photos[0])} crossorigin="anonymous" className="img-fluid" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-7">
                                                                        <div class="team-single-content">
                                                                            <div class="team-single-name">
                                                                                <h3>{bookingDetail?.vendorData?.firstName} {bookingDetail?.vendorData?.lastName}</h3>

                                                                            </div>

                                                                            <div class="team-single-info">
                                                                                <ul>
                                                                                    <li>
                                                                                        <span class="team-single-info-left"><i class="far fa-phone"></i>
                                                                                            Phone:</span>
                                                                                        <span class="team-single-info-right">{bookingDetail.vendorData.phoneNumber}</span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span class="team-single-info-left"><i class="far fa-phone"></i>
                                                                                            Alternate Contact:</span>
                                                                                        <span class="team-single-info-right">{bookingDetail.vendorData?.VendorDetail?.alternativePhoneNumber}</span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span class="team-single-info-left"><i class="far fa-envelope"></i>
                                                                                            Email:</span>
                                                                                        <span class="team-single-info-right">{bookingDetail.vendorData.email}</span>
                                                                                    </li>
                                                                                    {/* <li>
                                                                                        <span class="team-single-info-left"><i class="far fa-tools"></i>
                                                                                            Driving License:</span>
                                                                                        <span class="team-single-info-right">{bookingDetail.driverData.DriverDetail.drivingLicenceNumber}</span>
                                                                                    </li> */}
                                                                                </ul>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div></>}
                                                        <div class="user-profile-card-header mt-5">
                                                            <h4 class="user-profile-card-title">User Details</h4>

                                                        </div>
                                                        <div class="row">
                                                            <div class="col-lg-4">
                                                                <div class="form-group">
                                                                    <label>Full Name</label>
                                                                    <input type="text" class="form-control" disabled={true} value={bookingDetail?.BookingDetails[0].customerData?.name} placeholder="Your Name" />
                                                                    <i class="far fa-user"></i>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4">
                                                                <div class="form-group">
                                                                    <label>Phone Number</label>
                                                                    <input type="text" class="form-control" disabled={true} value={bookingDetail?.BookingDetails[0].customerData?.phoneNumber} placeholder="Your Phone" />
                                                                    <i class="far fa-phone"></i>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4">
                                                                <div class="form-group">
                                                                    <label>Email</label>
                                                                    <input type="text" class="form-control" disabled={true} value={bookingDetail?.BookingDetails[0].customerData?.email} placeholder="Your Email" />
                                                                    <i class="far fa-envelope"></i>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="form-group">
                                                                    <label>Pick Up Location</label>
                                                                    <input type="text" class="form-control" disabled={true} value={bookingDetail?.BookingDetails[0].pickupLocation.addressLine} placeholder="Type Location" />
                                                                    <i class="far fa-location-dot"></i>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="form-group">
                                                                    <label>Drop Off Location</label>
                                                                    <input type="text" class="form-control" disabled={true} value={bookingDetail?.BookingDetails[0].dropLocation.addressLine} placeholder="Type Location" />
                                                                    <i class="far fa-location-dot"></i>
                                                                </div>
                                                            </div>


                                                            <div class="col-lg-6">
                                                                <div class="form-group">
                                                                    <label>Pick Up Date </label>
                                                                    <input type="text" class="form-control " disabled={true} value={moment(bookingDetail?.tripStartDate).format('DD/MM/YY, hh:mm a')} placeholder="MM/DD/YY" />
                                                                    <i class="far fa-calendar-days"></i>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="form-group">
                                                                    <label>Dropping date</label>
                                                                    <input type="text" class="form-control" disabled={true} value={moment(bookingDetail?.tripEndDate).format('DD/MM/YY, hh:mm a')} placeholder="00:00 AM" />
                                                                    <i class="far fa-calendar-days"></i>
                                                                </div>
                                                            </div>


                                                            <div class="col-lg-12">
                                                                <div class="form-group">
                                                                    <label>Your Message</label>
                                                                    <textarea class="form-control" rows="5" disabled={true} value={bookingDetail.BookingDetails[0].customerNote}
                                                                        placeholder="Write Your Message"></textarea>
                                                                </div>
                                                            </div>
                                                            {(bookingDetail.BookingDetails[0].bookingStatus == 'started' || bookingDetail.BookingDetails[0].bookingStatus == 'ended') && <>
                                                                <div class="row align-items-center mt-5">
                                                                    <a className="col-md-6 col-12"><div class="taxi-img">
                                                                        <img src={constant.IMAGE_URL + bookingDetail?.vendorData.VendorDetail.documents} crossorigin="anonymous" width={'350px'} />
                                                                    </div></a>
                                                                    <div class="taxi-content col-md-6 col-12">
                                                                        <div class="taxi-head">
                                                                            <h4>Payment Break Down</h4>

                                                                        </div>
                                                                        <div class="taxi-feature">
                                                                            <ul className="">

                                                                                <li> Total Amount to be paid: <span> <b>Rs. {((bookingDetail.distanceBreakdown.distance / 1000) * (bookingDetail.isAcAvailable ? bookingDetail.VendorCar?.perKmCharge+1 : bookingDetail.VendorCar?.perKmCharge)).toFixed(0)}</b></span></li>
                                                                                {/* <li> Amount paid online: <span> <b className="bg-success text-white">Rs. {isPayment && isPayment / 100}</b></span></li> */}
                                                                                {/* <li> Amount remaining: <span> <b className="bg-warning text-white">Rs. {(((bookingDetail.distanceBreakdown.distance / 1000) * (bookingDetail.isAcAvailable ? bookingDetail.VendorCar?.perKmCharge+1 : bookingDetail.VendorCar?.perKmCharge)) - (isPayment / 100)).toFixed(0)}</b></span></li> */}
                                                                                <li>Add meal cost: <span> <input disabled={bookingDetail.BookingDetails[0].bookingStatus == 'ended'} type="number" className="border-0 text-right" onChange={(e) => setMealCost(e.target.value)} value={mealCost} placeholder="Enter Meal Cost" /></span></li>
                                                                                <li>Add toll tax: <span> <input disabled={bookingDetail.BookingDetails[0].bookingStatus == 'ended'} type="number" className="border-0 text-right" onChange={(e) => setTollTax(e.target.value)} value={tollTax} placeholder="Enter Toll Tax" /></span></li>
                                                                                <li>Add other charges: <span> <input disabled={bookingDetail.BookingDetails[0].bookingStatus == 'ended'} type="number" className="border-0 text-right" onChange={(e) => setOtherCost(e.target.value)} value={otherCost} placeholder="Enter Other Charges" /></span></li>
                                                                                <hr />
                                                                                <li>Total Amount remaining: <span> <h4>Rs. {bookingDetail && calculationHandler()}</h4></span></li>
                                                                                <li>Payment Mode: <span>
                                                                                    <select className="border-0 text-right" disabled={bookingDetail.BookingDetails[0].bookingStatus == 'ended'} value={paymentMode} onChange={(e) => setPaymentMode(e.target.value)}>
                                                                                        <option>Choose payment mode</option>
                                                                                        <option value={'cash'}>Cash</option>
                                                                                        <option value={'online'}>Online</option>
                                                                                    </select></span></li>
                                                                                {paymentMode == 'online' && <li>Payment reference <span> <input disabled={bookingDetail.BookingDetails[0].bookingStatus == 'ended'} type="text" className="border-0 text-right" onChange={(e) => setPaymentRef(e.target.value)} value={paymentRef} placeholder="Payment ref" /></span></li>}


                                                                            </ul>
                                                                        </div>

                                                                    </div>
                                                                    {bookingDetail.BookingReviews.length > 0 && <><div class="col-lg-6">
                                                                    <label>Share your Rating</label> <br/>
                                                                        <Rating
                                                                            initialValue={bookingDetail.BookingReviews[0].rating}
                                                                        />
                                                                    </div>


                                                                    <div class="col-lg-12">
                                                                        <div class="form-group">
                                                                            <label>Share your review</label>
                                                                            <textarea class="form-control" rows="5" value={bookingDetail.BookingReviews[0].comment} 
                                                                                placeholder="Write Your Message"></textarea>
                                                                        </div>
                                                                    </div></>}
                                                                </div>
                                                            </>}
                                                          {(bookingDetail?.sharingAvailable > 0 || bookingDetail?.BookingDetails[0]?.amountBreakdown?.remainingAmount) && <div class="table-responsive">
                                            <table class="table text-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th className="">Customer Name</th>
                                                        <th>Passengers</th>
                                                        <th>Total</th>
                                                        <th>Paid</th>
                                                        <th>Remaining</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bookingDetail?.BookingDetails?.map((item, index)=>{return(<tr key={item.id}>
                                                        <td>
                                                            <div class="table-list-info">
                                                                <a href="#">
                                                                    {/* <img src="/assets/img/taxi/01.png" alt/> */}
                                                                    <div class="table-list-content">
                                                                        <h6>{item.customerData.name}</h6>
                                                                        <span>{item.customerData.phoneNumber}</span>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {item.passengerCount}
                                                        </td>
                                                        <td>
                                                          Rs. {calculationSharingHandler(item)}
                                                        </td>
                                                        <td>Rs. {item.amountBreakdown.commissionPaid}</td>
                                                        
                                                        <td>
                                                           Rs. {index == 0 ? calculationSharingHandler(item) - item.amountBreakdown.commissionPaid : calculationSharingHandler(item)}
                                                        </td>
                                                    </tr>)})}
                                                   
                                                </tbody>
                                            </table>
                                        </div>}
                                                            <div class="col-lg-9 mx-auto d-flex mt-4">

                                                                {bookingDetail.BookingDetails[0].bookingStatus == 'confirmed' && <button onClick={() => changeStatusHandler()} class="theme-btn mx-4" >Start Trip<i
                                                                    class="fas fa-arrow-right"></i></button>}
                                                                    {bookingDetail.BookingDetails[0].bookingStatus == 'started' && <button onClick={() => {(bookingDetail?.sharingAvailable > 0 || bookingDetail?.BookingDetails[0]?.amountBreakdown?.remainingAmount) ? completeSharingTripHandler() : completeTripHandler()}} class="theme-btn mx-4" >End Trip<i
                                                                    class="fas fa-arrow-right"></i></button>}
                                                            </div>



                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
        {/* <Footer /> */}
    </>
    )
}
export default OrderDetails