import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDALm3T8dXNOcehLw-86efBlqKOrFSHnkU",
    authDomain: "cars53-customer.firebaseapp.com",
    projectId: "cars53-customer",
    storageBucket: "cars53-customer.appspot.com",
    messagingSenderId: "551807269555",
    appId: "1:551807269555:web:5eb38ce462150ac837cec6",
    measurementId: "G-76KGGH9ZR0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// Function to register the service worker
export const registerServiceWorker = async () => {
    try {
        const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
        console.log('Service Worker registered with scope:', registration.scope);
        return registration;
    } catch (error) {
        console.error('Service Worker registration failed:', error);
        throw error;
    }
};


// Function to request notification permission and get the token
export const requestPermission = async () => {
    return new Promise(async (resolve, reject) => {
    try {
       

        // Ensure the service worker is ready
        await navigator.serviceWorker.ready;
        console.log('Service Worker is ready.');

        // Request notification permission
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            console.log('Notification permission granted.');

            // Get the FCM token
            const vapidKey = 'BLdAciFOLyU5ND_kRIMMCQlHTGYKtwFsYlwtfgJ4DOj5-5sdvlxK8kJu1DDZ-eYwX863QODnMM-YMzokH0BYbjg'; // Replace with your VAPID key
            const token = await getToken(messaging, { vapidKey });

            if (token) {
                console.log('FCM Token:', token);
                resolve(token)
                // Send the token to your server for future messaging
            } else {
                console.log('No registration token available.');
            }
        } else {
            console.log('Unable to get permission to notify.');
            reject('Unable to get permission to notify.')
        }
    } catch (error) {
        console.error('An error occurred while requesting permission or getting the token:', error);
        reject(error)
        // await requestPermission();
        
    }
});
};
// Listen for incoming messages
onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // Customize notification handling here
});
