import { Link, useNavigate } from "react-router-dom"
import DashboardSideBar from "../../components/dashboardSidebar"
import Footer from "../../components/footer"
import Header from "../../components/header"
import SideBar from "../../components/sidebar"
import { useEffect, useState } from "react"
import services from "../../services"
import constant from "../../constant";
import Toaster from "../../components/toaster";
import "react-toggle/style.css" // for ES6 modules
import Toggle from 'react-toggle'

const AddCar = () => {
    let navigate = useNavigate();
    const [carsList, setCarsList] = useState()
    const [carData, setCarData] = useState()
    const [isAcAvailable, setIsAcAvailable] = useState(false)
    const [isCarrierAvailable, setIsCarrierAvailable] = useState(false)
    const [toaster, setToaster] = useState({ visiblity: "hide" });
    const getMasterCars = async () => {
        try {
            const response = await services.Cars.GET_CAR();
            console.log(response)
            setCarsList(response.data)
        } catch (error) {
            console.log(error)
        }
    }
    const HandleOnChangeValue = (e, key) => {
        let obj = { ...carData }
        obj[key] = e;
        setCarData({ ...obj })
    }
    useEffect(() => {
        getMasterCars()
    }, [])
    const uploadBusinessImage = async (e, type) => {
        const formData = new FormData();
        let categoryImage = e.target.files[0];
        formData.append("file", categoryImage);

        try {
            const response = await services.Cars.UPLOAD_IMAGE(formData);
            console.log(response)
            if (type == 'car') {
                let arr = carData.photos || []
                arr.push(response.data.url)
                HandleOnChangeValue(arr, 'photos')
            } else {
                let arr = carData.docs || []
                arr.push(response.data.url)
                HandleOnChangeValue(arr, 'docs')
            }
        } catch (error) {
            console.log(error);
        }

    };
    const onSubmitHandler = async () => {
        try {
            let data = carData;
            data.isCarrierAvailable = isCarrierAvailable;
            data.isAcAvailable = isAcAvailable;

            const response = await services.Cars.CREATE_CAR(data);
            console.log(response)
            setToaster({
                type: "success",
                title: "Car Added",
                text: 'Car Added successfully',
                visiblity: "show",
            });
            setTimeout(() => {
                navigate('/cars')

            }, 1500)
        } catch (error) {
            console.log(error)
            setToaster({
                type: "danger",
                title: "Error Occured",
                text: error.response.data.message,
                visiblity: "show",
            });
            setTimeout(() => {
                setToaster({
                    visiblity: "hide",
                });

            }, 1500)
        }
    }
    return (<>
        <Header />
        <SideBar />
        <main class="main">

            <Toaster
                type={toaster.type}
                title={toaster.title}
                text={toaster.text}
                visiblity={toaster.visiblity}
            />


            <div class="user-profile py-5">
                <div class="container">
                    <div class="row">
                        <DashboardSideBar />
                        <div class="col-lg-9">
                            <div class="user-profile-wrapper">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="user-profile-card">
                                            <div class="user-profile-card-header">
                                                <h4 class="user-profile-card-title">Add Car</h4>

                                            </div>
                                            <div class="user-profile-form">

                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Select Car</label>
                                                            <select value={carData?.carId} onChange={(e) => HandleOnChangeValue(e.target.value, 'carId')} class="form-control">
                                                                <option>Select</option>
                                                                {carsList && carsList.length && carsList.map((item) => {
                                                                    return (
                                                                        <option value={item.id}>{item.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Car Model Year</label>
                                                            <input type="text" onChange={(e) => HandleOnChangeValue(e.target.value, 'year')} class="form-control" value={carData?.year}
                                                                placeholder="Car Model Year" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Car RC Number</label>
                                                            <input type="text" onChange={(e) => HandleOnChangeValue(e.target.value, 'rcNumber')} class="form-control" value={carData?.rcNumber}
                                                                placeholder="Car RC Number" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Per Km Charge (without AC)</label>
                                                            <input type="text" onChange={(e) => HandleOnChangeValue(e.target.value, 'perKmCharge')} class="form-control" value={carData?.perKmCharge}
                                                                placeholder="perKmCharge" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 align-items-center d-flex">
                                                        <div class="form-group mb-0">
                                                            <label class="align-items-center d-flex gap-2">
                                                                <Toggle
                                                                    defaultChecked={isAcAvailable}
                                                                    onChange={(e) => setIsAcAvailable(e.target.checked)} />
                                                                <span>Air Conditioner</span>
                                                            </label>
                                                            <label class="align-items-center d-flex gap-2">
                                                                <Toggle
                                                                    defaultChecked={isCarrierAvailable}
                                                                    onChange={(e) => setIsCarrierAvailable(e.target.checked)} />
                                                                <span>Carrier</span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Car Photos</label>
                                                            <input onChange={(e) => uploadBusinessImage(e, 'car')} type="file" class="form-control"
                                                                placeholder="Car Photos" />
                                                        </div>
                                                        {carData?.photos && carData?.photos.length && carData?.photos.map((item) => {
                                                            return (
                                                                <img style={{ maxHeight: '200px' }} crossorigin="anonymous" src={constant.IMAGE_URL + item} />
                                                            )
                                                        })

                                                        }
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Documents (jpeg only)</label>
                                                            <input onChange={(e) => uploadBusinessImage(e)} type="file" class="form-control"
                                                                placeholder="Car Photos" />
                                                        </div>
                                                        {carData?.docs && carData?.docs.length && carData?.docs.map((item) => {
                                                            return (
                                                                <img style={{ maxHeight: '200px' }} crossorigin="anonymous" src={constant.IMAGE_URL + item} />
                                                            )
                                                        })

                                                        }
                                                    </div>

                                                </div>
                                                <button type="button" onClick={onSubmitHandler} class="theme-btn my-3"><span
                                                    class="far fa-user"></span> Save Changes</button>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
        <Footer />
    </>
    )
}
export default AddCar