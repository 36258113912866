import { Link } from "react-router-dom"
import DashboardSideBar from "../../components/dashboardSidebar"
import Footer from "../../components/footer"
import Header from "../../components/header"
import SideBar from "../../components/sidebar"
import services from "../../services"
import { useEffect, useState } from "react"
import moment from 'moment'
const MyCars = () =>{
    const [carsList,setCarsList] = useState()
    const getMyCarHandler = async() =>{
        try {
            const response = await services.Cars.GET_VENDOR_CAR();
            setCarsList(response.data)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(()=>{
        getMyCarHandler()
    },[])
    return (<>
    <Header/>
    <SideBar/>
        <main class="main">

       


        <div class="user-profile py-5">
            <div class="container">
                <div class="row">
                   <DashboardSideBar/>
                    <div class="col-lg-9">
                        <div class="user-profile-wrapper">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="user-profile-card">
                                        <div class="user-profile-card-header">
                                            <h4 class="user-profile-card-title">My Cars</h4>
                                            <div class="user-profile-card-header-right">
                                                <div class="user-profile-search">
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" placeholder="Search..."/>
                                                        <i class="far fa-search"></i>
                                                    </div>
                                                </div>
                                                <Link to={'/addcar'} class="theme-btn"><i class="far fa-plus"></i> Add car</Link>
                                            </div>
                                        </div>
                                        <div class="table-responsive">
                                            <table class="table text-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>Cab Info</th>
                                                        <th>Cab Model</th>
                                                        <th>Cab RC</th>
                                                        {/* <th>Driver Assigned</th> */}
                                                        <th>Created Date</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {carsList && carsList.length ? carsList.map((item, indx)=>{return(
                                                    <tr key={indx}>
                                                        <td>
                                                            <div class="table-list-info">
                                                                <a href="#">
                                                                   
                                                                    <div class="table-list-content">
                                                                        <h6>{item.Car.name} {item.Car.brand}</h6>
                                                                        <span>Rs.  {item.perKmCharge}</span> <span>AC : {item.isAcAvailable ? 'Yes' : 'No'}</span>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                        {item.year}
                                                        </td>
                                                        <td>
                                                        {item.rcNumber}
                                                        </td>
                                                        {/* <td>
                                                        <div class="table-list-info">
                                                                <a href="#">
                                                                
                                                                    <div class="table-list-content">
                                                                        <h6>{item.DriverCar?.User?.firstName || 'NA'} {item.DriverCar?.User?.lastName || ''}</h6>
                                                                       
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </td> */}
                                                        <td>
                                                            <span>{moment(item.createdAt).format('DD-MM-YYYY,hh:mm')}</span>
                                                        </td>
                                                       
                                                        <td><span class={`badge badge-success`}>{item.isActive ? 'Active' : 'Inactive'}</span></td>
                                                        <td>
                                                            <Link to={`/car/${item.id}`}
                                                                class="btn btn-outline-secondary btn-sm rounded-2"
                                                                data-bs-toggle="tooltip" title="Details"><i
                                                                    class="far fa-eye"></i></Link>
                                                                    <Link to={`/car/edit/${item.id}`}
                                                                class="btn ms-2 btn-outline-secondary btn-sm rounded-2"
                                                                data-bs-toggle="tooltip" title="Details"><i
                                                                    class="far fa-pencil"></i></Link>
                                                        </td>
                                                    </tr>
                                                    )}) : null
                                                    }
                                                   
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="pagination-area d-none">
                                            <div aria-label="Page navigation example">
                                                <ul class="pagination my-3">
                                                    <li class="page-item">
                                                        <a class="page-link" href="#" aria-label="Previous">
                                                            <span aria-hidden="true"><i
                                                                    class="far fa-angle-double-left"></i></span>
                                                        </a>
                                                    </li>
                                                    <li class="page-item active"><a class="page-link" href="#">1</a>
                                                    </li>
                                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                    <li class="page-item">
                                                        <a class="page-link" href="#" aria-label="Next">
                                                            <span aria-hidden="true"><i
                                                                    class="far fa-angle-double-right"></i></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </main>
    <Footer/>
    </>
    )
}
export default MyCars