/* eslint-disable import/no-anonymous-default-export */
import Auth from "./auth";
import order from "./order";
import Cars from "./masterCars";
import Vendor from "./vendor";
export default {
  Auth,
  order,
  Cars,
  Vendor,
};
