import { Link, useParams } from "react-router-dom"
import DashboardSideBar from "../../components/dashboardSidebar"
import Footer from "../../components/footer"
import Header from "../../components/header"
import SideBar from "../../components/sidebar"
import { useState, useEffect } from "react"
import services from "../../services"
import constant from "../../constant"

const CarDetails = () => {
    let { id } = useParams();
    const [carData, setCarData] = useState()
    useEffect(() => {
        getCarDetail(id);
    }, [])
    const getCarDetail = async (id) => {
        try {
            const response = await services.Cars.GET_VENDOR_CAR_ID(id);
            console.log(response)
            setCarData(response.data)
        } catch (error) {
            console.log(error)
        }
    }
    return (<>
        <Header />
        <SideBar />
        <main class="main">




            <div class="user-profile py-5">
                <div class="container">
                    <div class="row">
                        <DashboardSideBar />
                        {carData && <div class="col-lg-9">
                            <div class="user-profile-wrapper">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="user-profile-card">
                                            <div class="user-profile-card-header">
                                                <h4 class="user-profile-card-title d-flex align-items-center justify-content-between w-100"><span> Cab Detail #{carData.id}</span> <Link to={`/car/edit/${carData.id}`} className="theme-btn">Edit Details</Link></h4>

                                            </div>
                                            <div class="row">

                                                <div class="col-md-12 mx-auto p-0">
                                                    <div class="booking-form shadow-none">

                                                        <div class="col-md-12 col-lg-12 filter-item cat1 cat2">
                                                            <div class="row align-items-center ">
                                                                <a className="col-md-4 col-12"><div class="taxi-img">
                                                                    <img crossorigin="anonymous" src={constant.IMAGE_URL + carData.photos[0]} width={'350px'} />
                                                                </div></a>
                                                                <div class="taxi-content col-md-7 col-12">
                                                                    <div class="taxi-head">
                                                                        <span> <b className="text-uppercase">{carData.Car.type}</b></span><h4>{carData.Car.brand} {carData.Car.name}</h4>

                                                                    </div>
                                                                    <div class="taxi-feature">
                                                                        <ul>
                                                                            <li><i class="far fa-car-side-bolt"></i> Charges Per KM: <span>{carData.Car.perKmCharge}</span></li>
                                                                            <li><i class="far fa-car-side-bolt"></i> Model: <span>{carData.year}</span></li>
                                                                            <li><i class="far fa-person-seat"></i> Passengers: <span>{carData.Car.customerCapacity}</span></li>
                                                                            <li><i class="far fa-suitcase-rolling"></i> Luggage Carrier: <span><b>{carData.isCarrierAvailable ? 'Yes' : 'No'}</b></span></li>
                                                                            <li><i class="far fa-heat"></i> Air Condition: <span><b>{carData.isAcAvailable ? 'Yes' : 'No'}</b></span></li>

                                                                            <li><i class="far fa-user-pilot"></i> Car Number: <span>{carData.rcNumber}</span></li>
                                                                        </ul>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div class="user-profile-card-header mt-5">
                                                            <h4 class="user-profile-card-title">Assigned Driver Details</h4>

                                                        </div>
                                                        {carData?.DriverCar?.User && <div class="team-single-wrapper">
                                                            <div class="row align-items-center">
                                                                <div class="col-lg-4">
                                                                    <div class="team-single-img">
                                                                    <img crossorigin="anonymous" src={constant.IMAGE_URL + carData.DriverCar.User.DriverDetail.photo} width={'350px'} />
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-7">
                                                                    <div class="team-single-content">
                                                                        <div class="team-single-name">
                                                                            <h3>{carData.DriverCar.User.firstName} {carData.DriverCar.User.lastName}</h3>
                                                                            <p>Driving License #{carData.DriverCar.User.DriverDetail.drivingLicenceNumber}</p>
                                                                        </div>
                                                                     
                                                                        <div class="team-single-info">
                                                                            <ul>
                                                                                <li>
                                                                                    <span class="team-single-info-left"><i class="far fa-phone"></i>
                                                                                        Phone:</span>
                                                                                    <span class="team-single-info-right">{carData.DriverCar.User.phoneNumber}</span>
                                                                                </li>
                                                                                <li>
                                                                                    <span class="team-single-info-left"><i class="far fa-envelope"></i>
                                                                                        Email:</span>
                                                                                    <span class="team-single-info-right"><a
                                                                                        
                                                                                        class="__cf_email__"
                                                                                        >{carData.DriverCar.User.email}</a></span>
                                                                                </li>
                                                                                <li>
                                                                                    <span class="team-single-info-left"><i class="far fa-tools"></i>
                                                                                        Aadhar:</span>
                                                                                    <span class="team-single-info-right">{carData.DriverCar.User.DriverDetail.aadharNumber}</span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        <hr />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>} */}


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>

        </main>
        <Footer />
    </>
    )
}
export default CarDetails