/*eslint-disable import/no-anonymous-default-export*/
import auth from "./auth";
import order from "./order";
import Cars from "./masterCars";
import Vendor from "./vendor";
export default {
  auth,
  order,
  Cars,
  Vendor,
};
