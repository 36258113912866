import { Link, useNavigate } from "react-router-dom"
import DashboardSideBar from "../../components/dashboardSidebar"
import Footer from "../../components/footer"
import Header from "../../components/header"
import SideBar from "../../components/sidebar"
import services from "../../services"
import { useEffect, useState } from "react"
import Toaster from "../../components/toaster";

import { isMobile } from 'react-device-detect';
import constant from "../../constant";
import moment from "moment";
const Dashboard = () => {
    const navigate = useNavigate()
    const [bookingList, setBookingList] = useState([])
    const [pendingbooking, setpendingbooking] = useState(0)
    const [driverList, setDriverList] = useState()
    const [selectedDriver, setSelectedDriver] = useState()

    const [completedbooking, setcompletedbooking] = useState(0)
    const [upcomingbooking, setupcomingbooking] = useState(0)
    const [carcount, setcarcount] = useState(0)
    const [drivercount, setdrivercount] = useState(0)
    const [totalamount, settotalamount] = useState(0)
    const [toaster, setToaster] = useState({ visiblity: "hide" });

    useEffect(() => {
        getBookingsHandler();
        getDriverData();
    }, [])
    const getDriverData = async() =>{
        try {
            const response = await services.Vendor.GET_DRIVER();
            console.log(response)
            setDriverList(response.data)
        } catch (error) {
            console.log(error)
        }
    }
    const getBookingsHandler = async () => {
        try {
            const response = await services.order.BOOKING_REQUESTS();
            const responseAll = await services.order.BOOKING_LISTING();
            const cars = await services.Cars.GET_VENDOR_CAR();
            const drivers = await services.Vendor.GET_DRIVER();
            setdrivercount(drivers.data.length)
            setcarcount(cars.data.length)
            let data = responseAll.data;
            let total = 0
            if (response.data.length) {
                response.data.map((item) => {
                    item.BookingDetails.sort(function (a, b) {
                        return parseFloat(a.id) - parseFloat(b.id);
                    });

                })
                console.log(response.data)
                setBookingList(response.data)
            }
            if (data.length) {
                data.map((item) => {
                    item.BookingDetails.sort(function (a, b) {
                        return parseFloat(a.id) - parseFloat(b.id);
                    });

                })

                var newArray = data.filter(function (el) {
                    return el.BookingDetails[0].bookingStatus == 'confirmed' || el.BookingDetails[0].bookingStatus == 'accepted';
                });
                var ended = data.filter(function (el) {
                    return el.BookingDetails[0].bookingStatus == 'ended';
                });
                ended.map((item) => {
                    item?.BookingDetails[0]?.BookingPayment?.BookingPaymentDetails?.map((payment) => {
                        if (payment.transactionData.razorpay_payment_id) {
                            total = total + (payment.amount / 100);
                        } else {
                            total = total + payment.amount
                        }
                    })
                })
                var pending = data.filter(function (el) {
                    return el.BookingDetails[0].bookingStatus == 'pending';
                });
                settotalamount(total)
                setupcomingbooking(newArray.length)
                setcompletedbooking(ended.length)
                setpendingbooking(pending.length)

            }
        } catch (error) {
            console.log(error)
        }
    }
    const acceptBookingHandler = async (item) => {
        try {
            const isConfirm = window.confirm('Are you sure you want to accept?')
            console.log(isConfirm)
            if (isConfirm) {
                let data = { bookingId: item.id, assignDriver: selectedDriver }
                const response = await services.order.ACCEPT_BOOKING(data)
                console.log(response)
                if (response) {
                    navigate('/mybookings')
                }
            }
        } catch (error) {
            console.log(error)
            if (error.response.data.message == "Vendor car does not exists!")
                setToaster({
                    type: "danger",
                    title: "Pending profile",
                    text: 'Driver is not assigned to this car!',
                    visiblity: "show",
                });

        }
    }
    const rejectBookingHandler = async (item) => {
        try {
            const isConfirm = window.confirm('Are you sure you want to reject?')
            console.log(isConfirm)
            if (isConfirm) {
                const response = await services.order.REJECT_BOOKING(item.id)
                console.log(response)
                if (response) {
                    navigate('/mybookings')
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    return (<>
        <Header />
        <SideBar />
        <main class="main">

            <Toaster
                type={toaster.type}
                title={toaster.title}
                text={toaster.text}
                visiblity={toaster.visiblity}
            />


            <div class="user-profile py-5">
                <div class="container">
                    <div class="row">
                        <DashboardSideBar />
                        <div class="col-lg-9">
                            <div class="user-profile-wrapper">
                                <div class="row">
                                    <div class="col-md-6 col-lg-4">
                                        <div class="dashboard-widget dashboard-widget-color-1">
                                            <div class="dashboard-widget-info">
                                                <h1>{upcomingbooking}</h1>
                                                <span>Upcoming Booking</span>
                                            </div>
                                            <div class="dashboard-widget-icon">
                                                <i class="fal fa-list"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4">
                                        <div class="dashboard-widget dashboard-widget-color-2">
                                            <div class="dashboard-widget-info">
                                                <h1>{completedbooking}</h1>
                                                <span>Completed Booking</span>
                                            </div>
                                            <div class="dashboard-widget-icon">
                                                <i class="fal fa-eye"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4">
                                        <div class="dashboard-widget dashboard-widget-color-3">
                                            <div class="dashboard-widget-info">
                                                <h1>{pendingbooking}</h1>
                                                <span>Pending Booking</span>
                                            </div>
                                            <div class="dashboard-widget-icon">
                                                <i class="fal fa-xmark-circle"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-lg-4">
                                        <div class="dashboard-widget dashboard-widget-color-3">
                                            <div class="dashboard-widget-info">
                                                <h1>Rs. {totalamount}</h1>
                                                <span>Total Earnings</span>
                                            </div>
                                            <div class="dashboard-widget-icon">
                                                <i class="fal fa-rupee"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4">
                                        <div class="dashboard-widget dashboard-widget-color-1">
                                            <div class="dashboard-widget-info">
                                                <h1>{carcount}</h1>
                                                <span>My Cars</span>
                                            </div>
                                            <div class="dashboard-widget-icon">
                                                <i class="fal fa-car"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4">
                                        <div class="dashboard-widget dashboard-widget-color-2">
                                            <div class="dashboard-widget-info">
                                                <h1>{drivercount}</h1>
                                                <span>My Drivers</span>
                                            </div>
                                            <div class="dashboard-widget-icon">
                                                <i class="fal fa-users"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="user-profile-card">
                                            <h4 class="user-profile-card-title">Booking Requests</h4>
                                            {isMobile ? <div class="container my-3">

                                                {bookingList?.map((item) => {
                                                    return (<div  class="card booking-card">
                                                        <div class="d-flex justify-content-between align-items-center">

                                                            <div class="booking-info">
                                                                <h6 className="mb-0">{item.Car.brand} {item.Car.name}</h6>
                                                                <span>Booking ID: #{item.id}</span>
                                                            </div>
                                                            <img src={constant.IMAGE_URL + item?.VendorCar.photos[0]} crossorigin="anonymous" class="rounded-circle" alt="Driver image" />
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <div class="mt-1">
                                                                <span><strong>From : </strong>{item?.BookingDetails[0].pickupLocation.addressLine}</span> <br />
                                                                <span><strong>to : </strong>{item?.BookingDetails[0].dropLocation.addressLine}</span>
                                                            </div>
                                                            <div class="mt-1">
                                                                <span>{moment(item.tripStartDate).format('DD/MM/YY, hh:mm')}</span>
                                                                <p>{moment(item.tripEndDate).format('DD/MM/YY, hh:mm')}</p>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex justify-content-between mt-2">
                                                            <span class={`badge badge-status badge-${(item.bookingStatus == 'failed' || item.bookingStatus == 'cancelled' || item.bookingStatus == 'rejected') ? 'danger' : 'primary'} text-capitalize`}>{(item.bookingStatus == 'failed' || item.bookingStatus == 'cancelled' || item.bookingStatus == 'rejected') ? item.bookingStatus : item.BookingDetails[0].bookingStatus}</span>
                                                            <strong>Rs. {((item.distanceBreakdown.distance / 1000) * (item.isAcAvailable ? item.VendorCar?.perKmCharge + 1 : item.VendorCar?.perKmCharge)).toFixed(0)}</strong>
                                                        </div>
                                                        <div class="d-flex justify-content-between mt-3">
                                                        <button onClick={() => acceptBookingHandler(item)}
                                                                            class="btn btn-success btn-sm rounded-2 me-2"
                                                                            data-bs-toggle="tooltip" title="Details"> <i
                                                                                class="far fa-check"></i> Accept </button>
                                                                        <button onClick={() => rejectBookingHandler(item)}
                                                                            class="btn btn-danger btn-sm rounded-2"
                                                                            data-bs-toggle="tooltip" title="Details"> <i
                                                                                class="far fa-multiply"></i> Reject </button>
                                                        </div>

                                                    </div>)
                                                })}



                                            </div>
                                                : <div class="table-responsive">
                                                    <table class="table text-nowrap">
                                                        <thead>
                                                            <tr>
                                                                <th className="">Cab Info</th>
                                                                <th>Journey Date</th>
                                                                <th>Pickup and drop</th>
                                                                <th>Price</th>
                                                                <th>Status</th>
                                                                {/* <th style={{minWidth:'170px'}}>Driver</th> */}
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {bookingList?.map((item) => {
                                                                return (<tr key={item.id}>
                                                                    <td>
                                                                        <div class="table-list-info">
                                                                            <a href="#">
                                                                                {/* <img src="/assets/img/taxi/01.png" alt/> */}
                                                                                <div class="table-list-content">
                                                                                    <h6>{item.Car.brand} {item.Car.name}</h6>
                                                                                    <span>Booking ID: #{item.id}</span>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <span>{moment(item.tripStartDate).format('DD/MM/YY, hh:mm')}</span>
                                                                        <p>{moment(item.tripEndDate).format('DD/MM/YY, hh:mm')}</p>
                                                                    </td>
                                                                    <td>
                                                                        <span>{item?.BookingDetails[0].pickupLocation.addressLine}</span> <br />
                                                                        <span>{item?.BookingDetails[0].dropLocation.addressLine}</span>
                                                                        {/* <p>03:15 PM</p> */}
                                                                    </td>
                                                                    <td>Rs. {((item.distanceBreakdown.distance / 1000) * (item.isAcAvailable ? item.VendorCar?.perKmCharge + 1 : item.VendorCar?.perKmCharge)).toFixed(0)}</td>
                                                                    <td><span class={`badge badge-${(item.bookingStatus == 'failed' || item.bookingStatus == 'cancelled' || item.bookingStatus == 'rejected') ? 'danger' : 'primary'} text-capitalize`}>{(item.bookingStatus == 'failed' || item.bookingStatus == 'cancelled' || item.bookingStatus == 'rejected') ? item.bookingStatus : item.BookingDetails[0].bookingStatus}</span></td>
                                                                    {/* <td> <select onChange={(e)=>setSelectedDriver(e.target.value)}  class="form-control">
                                                                <option>Select</option>
                                                               
                                                                {driverList && driverList.length && driverList.map((item)=> {return (
                                                                     <option value={item.id}>{item.firstName} {item.lastName}</option>
                                                                )})}
                                                           </select></td> */}
                                                                    <td>
                                                                        <button onClick={() => acceptBookingHandler(item)}
                                                                            class="btn btn-success btn-sm rounded-2 me-2"
                                                                            data-bs-toggle="tooltip" title="Details"> <i
                                                                                class="far fa-check"></i> Accept </button>
                                                                        <button onClick={() => rejectBookingHandler(item)}
                                                                            class="btn btn-danger btn-sm rounded-2"
                                                                            data-bs-toggle="tooltip" title="Details"> <i
                                                                                class="far fa-multiply"></i> Reject </button>

                                                                    </td>
                                                                </tr>)
                                                            })}

                                                        </tbody>
                                                    </table>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
        <Footer />
    </>
    )
}
export default Dashboard